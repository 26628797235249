
import '@/assets/css/iframeStyle.scss';
import { Component, Vue } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";
import ProvinceCitySelect from "@/components/ProvinceCitySelect.vue";
import TrainList from "./component/TrainList.vue";
import PhoneEditDialog from "@/components/PhoneEditDialog.vue";
import { ElForm } from 'element-ui/types/form';

@Component({
    name: "PerMaterial",
    components: {
        ContentTitle,
        ProvinceCitySelect,
        TrainList,
        PhoneEditDialog,
    },
})
export default class PerMaterial extends Vue {
    private contentTitle: string = "个人资料";
    private baseUrlIframe = process.env.VUE_APP_URL;
    private activePage: number = 1;
    private showTips: boolean = false;
    private loading: boolean = true;
    private userInfo: any = {};
    private nickName = '';
    private formData: any = {
        realName: null,
        gender: null,
        position: null,
        companyName: null,
        provinceCode: null,
        cityCode: null,
        industryLevelList: [],  // 所属行业
        labels: [], // 标签
    };
    private editUserName: boolean = false;
    private phoneEditFlag: boolean = false; // 手机修改弹窗
    private editUserInfo: boolean = false;
    private inputVisible: boolean = false;
    private inputValue: string =  '';
    // 多级联动
    private props: any = {
        lazy: true,
        lazyLoad: this.industryLazyLoad,
    };
    private industryLazyLoad(node: any, resolve: any) {
        const { level } = node;
        const paramData: any = {};
        paramData.parentId = node.value ? node.value : 0;
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/config/industrial-categories')
        .then((res: any) => {
            const nodes: any = [];
            if (res && res.length > 0) {
                res.forEach((item: any) => {
                    nodes.push({
                        value: item.id,	// 选项值
                        label: item.name,	// 选项名
                        leaf: level >= 3,	// 是否为末尾
                    });
                });
            }
            resolve(nodes);
        });
    }
    private handleCascadeVisibleChange(val: any) {
        if (!val) {
            const sysCascadeRef: any = this.$refs.sysCascade;
            const panelRefs: any = sysCascadeRef.$refs.panel;
            if (sysCascadeRef) {
                // panelRefs.checkedValue = []; // 也可以是指定的值，默认返回值是数组，也可以返回单个值
                panelRefs.activePath = [];
                panelRefs.syncActivePath();
            }
        }
    }

    private created() {
        this.getCurrent();
        // this.$nextTick(() => {
        //     // 获取用户信息
        //     this.userInfo = this.$store.state.userInfo;
        // });
    }
    private propBack(value: boolean) {
        this.activePage = 1;
    }
    // 选择省市
    private selectReturn(obj: any) {
        this.formData.cityCode = obj.city;
        this.formData.provinceCode = obj.province;
    }
    // 选择头像
    private chooseFile(event: any) {
        const fileData = event.target.files[0];
        const param = new FormData();
        param.append('file', fileData);
        this.$httpService.postData(param, '/apiProxy/api/frontend/file/upload_image', {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        }).then((res: any) => {
            this.userInfo.avatar = res.path;
            this.$httpService.putData({avatar: res.path},
            '/apiProxy/api/frontend/individual/profile')
            .then((putRes: any) => {
                this.$message({
                    message: '修改成功！',
                    type: 'success',
                });
                this.getCurrent();
                this.saveStoreUserInfo({avatar: res.path});
            });
        });
    }
    // 获得用户信息
    private getCurrent() {
        this.$httpService.getData({}, '/apiProxy/api/frontend/individual/profile').then((res: any) => {
            this.userInfo = Object.assign({}, res);
            this.nickName = res.nickName;
            this.resetFormData(res);
            this.loading = false;
        }).catch(() => {
            this.loading = false;
        });
    }
    // 判断是否显示个人信息顶部提示
    private loopFormData() {
        this.showTips = false;
        const arr: any = Object.keys(this.formData);
        for (const key of arr) {
            const value = this.formData[key];
            console.log(key, value);
            if (typeof(value) === 'string' && value.length <= 0) {
                this.showTips = true;
                break;
            }
            if (typeof(value) === 'number' && (value === null || value === undefined)) {
                this.showTips = true;
                break;
            }
            if (typeof(value) === 'object' && value.length <= 0) {
                this.showTips = true;
                break;
            }
            if (key === 'industryLevelList' && value[0] === null) {
                this.showTips = true;
                break;
            }
        }
    }
    private resetFormData(res: any) {
        this.formData = {
            realName: res.realName,
            gender: res.gender,
            position: res.position,
            companyName: res.companyName,
            provinceCode: res.provinceCode,
            cityCode: res.cityCode,
            industryLevelList: [].concat(res.industryLevelList),  // 所属行业
            labels: [].concat(res.labels), // 标签
        };
        const formRef = this.$refs.formData! as ElForm;
        formRef.resetFields();
        this.loopFormData();
    }
    // 修改全局用户信息
    private saveStoreUserInfo(info: any) {
        const saveUserInfo = {
            ...this.$store.state.userInfo,
            ...info,
        };
        this.$store.commit('saveUserInfo', saveUserInfo);
    }
    // 保存昵称修改
    private saveNickName() {
        this.$httpService.putData({nickName: this.nickName}, '/apiProxy/api/frontend/individual/profile')
        .then((res: any) => {
            this.$message({
                message: '修改成功！',
                type: 'success',
            });
            this.getCurrent();
            this.saveStoreUserInfo({nickname: this.nickName});
            this.editUserName = false;
        });
    }
    // 修改个人信息
    private saveUserInfo() {
        const formRef = this.$refs.formData! as ElForm;
        formRef.validate((valid) => {
            if (valid) {
                this.$httpService.putData(this.formData, '/apiProxy/api/frontend/individual/profile')
                .then((res: any) => {
                    this.$message({
                        message: '修改成功！',
                        type: 'success',
                    });
                    this.getCurrent();
                    this.editUserInfo = false;
                });
            }
        });
    }
    // 修改个人信息
    private startEditUser() {
        this.editUserInfo = true;
    }
    // 修改个人信息
    private endEditUser() {
        this.editUserInfo = false;
        this.resetFormData(this.userInfo);
        // this.formData.labels = this.userInfo.labels;
        // this.resetFormData(this.userInfo);
    }
    // 手机修改弹窗
    private phoneEdit() {
        this.phoneEditFlag = true;
    }
    // 接收手机关闭弹窗传回来的值
    private phoneCloseMsg(num: number) {
        this.phoneEditFlag = false;
        if (num === 3) {
            // 点击了下一步
            this.getCurrent();
        }
    }
    // start-个人标签
    private handleClose(tag: any) {
        this.formData.labels.splice(this.formData.labels.indexOf(tag), 1);
    }
    private showInput() {
        this.inputVisible = true;
        this.$nextTick(() => {
            const saveTagInput: any = this.$refs.saveTagInput;
            saveTagInput.$refs.input.focus();
        });
    }
    private handleInputConfirm() {
        const inputValue = this.inputValue;
        if (inputValue) {
            this.formData.labels.push(inputValue);
        }
        this.inputVisible = false;
        this.inputValue = '';
    }
    // end-个人标签
    // 选择文件
    private openFile() {
        const file: any = this.$refs.file;
        file.click();
    }
}
