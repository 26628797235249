
import '@/assets/css/iframeStyle.scss';
import { Component, Vue, Watch } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";
import PwdEditDialog from "@/components/PwdEditDialog.vue";
import PhoneEditDialog from "@/components/PhoneEditDialog.vue";
import { formatTime } from '@/utils/utils';

@Component({
    name: "Certification",
    components: {
        ContentTitle,
        PwdEditDialog,
        PhoneEditDialog,
    },
})
export default class Certification extends Vue {
    private formatTime: any = formatTime;
    private contentTitle: string = "安全设置";
    private useInfo: any = {};
    private pwdEditFlag: boolean = false; // 密码修改弹窗
    private phoneEditFlag: boolean = false; // 手机修改弹窗
    private emailEditFlag: boolean = false; // 邮箱绑定弹窗
    private baseUrl: string = '';
    private isBindEmail: boolean = false;

    private created() {
        this.$nextTick(() => {
            // 获取用户信息
            this.useInfo = this.$store.state.userInfo;
            if (this.useInfo.email && this.useInfo.email !== '') {
                this.isBindEmail = true;
            } else {
                this.isBindEmail = false;
            }
            if (process.env.NODE_ENV === 'development') {
                this.baseUrl = '/apiProxy';
            }
        });
    }

    // 密码修改弹窗
    private pwdEdit() {
        this.pwdEditFlag = true;
    }
    // 接收关闭弹窗传回来的值
    private propCloseMsg(num: number) {
        this.pwdEditFlag = false;
        if (num === 3) {
            this.getCurrent();
        }
    }
    // 手机修改弹窗
    private phoneEdit() {
        this.phoneEditFlag = true;
    }
    // 接收手机关闭弹窗传回来的值
    private phoneCloseMsg(num: number) {
        this.phoneEditFlag = false;
    }

    // 跳转至企业资料页面
    private toCertification() {
        // this.$router.push({
        //     path: "/company/certification",
        //     query: {isBack: '1', isCert: '1'},
        // }).catch((err) => err);
        this.$router.push({
            path: "/company?url=/v2/user/company/uc/certification.htm",
        }).catch((err) => err);
    }
    // 选择头像
    private chooseFile(event: any) {
        const fileData = event.target.files[0];
        const param = new FormData();
        param.append('file', fileData);
        this.$httpService.postData(param, '/apiProxy/api/frontend/file/upload_image', {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        }).then((res: any) => {
            this.useInfo.avatar = res.path;
            this.$httpService.putData({avatar: res.path},
            '/apiProxy/api/frontend/users/avatar')
            .then((putRes: any) => {
                this.$message({
                    message: '修改成功！',
                    type: 'success',
                });
                this.getCurrent();
            });
        });
    }
    // 获得用户信息
    private getCurrent() {
        this.$httpService.getData({}, '/apiProxy/api/frontend/users/current').then((res: any) => {
            this.$store.commit('saveUserInfo', res);
            this.useInfo = res;
            if (this.useInfo.email && this.useInfo.email !== '') {
                localStorage.setItem("userEmail", JSON.stringify(this.useInfo.email));
                this.isBindEmail = true;
            } else {
                this.isBindEmail = false;
            }
        });
    }
    private bindEmail() {
        this.emailEditFlag = true;
    }
    private emailCloseMsg(num: number) {
        this.emailEditFlag = false;
        if (num === 3) {
            this.getCurrent();
        }
    }
}
