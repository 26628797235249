
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";

@Component({
    name: 'TrainList',
    components: {
        ContentTitle,
    },
})
export default class TrainList extends Vue {
    private baseUrlIframe = process.env.VUE_APP_URL;
    private height: any = 'calc(100vh - 360px)';
    private contentTitle: string = "个人摩尔学堂培训记录";
    private searchParam: any = {
        pageSize: 10,
        pageNo: 1,
        companyName: '',
        certificateNumber: '',
        courseNumber: '',
        trainTypeList: '',
        qualified: '',
    };
    private multipleSelect: any = { // 多选下拉框
        copy_trainTypeList: '',
    };
    private total: number = 0;
    private tableData = [];
    private tableObj: any = {
        trainFpy: 0,
        trainPass: 0,
    };
    private changeMobileNum() {
        this.searchParam.mobile = this.searchParam.mobile.replace(/[^\d]/g, '');
    }
    private mounted() {
        this.height = window.innerHeight - 360;
        window.onresize = () => {
            return (() => {
                this.height = window.innerHeight - 360;
            })();
        };
    }
    // 给父组件传值
    @Emit('backHandle')
    private backHandleTodo(num: number): number {
        return num;
    }
    private created() {
        this.$nextTick(() => {
            this.getDataList();
        });
    }
    private handleSizeChange(val: number) {
        this.searchParam.pageSize = val;
        this.getDataList();
    }
    private handleCurrentChange(val: number) {
        this.searchParam.pageNo = val;
        this.getDataList();
    }
    private getDataList() {// 获取列表数据
        this.searchParam.trainTypeList = this.multipleSelect.copy_trainTypeList.toString();
        const searchParam: any = Object.assign({}, this.searchParam);
        this.$httpService.getData(searchParam, '/apiProxy/api/frontend/train/student')
        .then((res: any) => {
            if (res && res.total > 0) {
                this.tableData = res.list;
                this.total = res.total;
                this.tableObj = {
                    trainFpy: res.trainFpy,
                    trainPass: res.trainPass,
                };
            } else {
                this.tableData = [];
                this.total = 0;
                this.tableObj = {
                    trainFpy: 0,
                    trainPass: 0,
                };
            }
        });
    }
    private onSearch() {// 查询
        this.total = 0;
        this.searchParam.pageNo = 1;
        this.getDataList();
    }
    private resetForm(formName: any) {// 重置
        const formRef: any = this.$refs[formName];
        formRef.resetFields();
        this.searchParam = {
            pageSize: 10,
            pageNo: 1,
            companyName: '',
            certificateNumber: '',
            courseNumber: '',
            trainTypeList: '',
            qualified: '',
        };
        this.multipleSelect = {
            copy_trainTypeList: '',
        };
        this.onSearch();
    }
    // 接收子页面传回来的值
    private propGoBlack() {
        this.backHandleTodo(0);
    }
    private async downloadClick(row: any) {
        if (!row.certificateUrl || row.certificateUrl === '') {
            this.$alert(`暂不支持下载，证书还在制作中，请您耐心等待！`, '提示', {
                showClose: false,
                confirmButtonText: '我知道了',
                callback: (action) => {
                    // ...
                },
            });
            return;
        }
        const certificateUrl = row.certificateUrl;
        const response = await fetch(this.baseUrlIframe + certificateUrl);
        const blob = await response.blob();
        this.triggerADownload(URL.createObjectURL(blob), row);
    }
    private triggerADownload(blob: any, row: any) {
        const a = document.createElement('a');
        const fileName = `${row.studentName}-${row.companyName}-结业证书`;
        document.body.appendChild(a);
        a.href = blob;
        a.download = fileName;
        a.click();
        document.body.removeChild(a);
    }
}
