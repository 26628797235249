
import { ElForm } from 'node_modules/element-ui/types/form';
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { verifyPhone } from '@/utils/utils';
@Component({
  name: 'PwdEditDialog',
})
export default class PwdEditDialog extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    }) private phoneEditFlag!: boolean; // 接收父组件传过来的值
    @Prop(String) private oldPhone!: number; // 接收父组件传过来的值

    private showDialog: boolean = false; // 弹窗显示与关闭    // 倒计时相关变量
    private step: number = 1;
    private codeFlag: boolean = false;
    private time: number = 60;
    private interVal: any = null;
    private form = {
        oldPhone: '',
        oldPhoneAbbr: '',
        newphone: '',
        regCode: '',
    };
    private rules: any = {
        regCode: [
            { required: true, message: '请输入验证码', trigger: 'blur' },
            // { validator: this.sendCode, trigger: 'blur' },
        ],
    };
    private validateMobile(rule: any, value: string, callback: any) {
        if (value !== '' && value !== null && !verifyPhone(value)) {
            callback(new Error('手机号格式不正确'));
        } else {
            callback();
        }
    }
    private sendCode(rule: any, value: any, callback: any) {
        if (this.codeFlag) {
            callback();
        } else {
            callback(new Error('请先点击发送验证码'));
        }
    }

    @Watch('phoneEditFlag') // 监听父组件传过来的值
    private phoneEditFlagFun(value: boolean) {
        this.showDialog = value;
    }

    @Watch('oldPhone') // 监听父组件传过来的值
    private oldPhoneFun(value: string) {
        const valLen = value.length;
        this.form.oldPhoneAbbr = value.substring(0, 3) + '****' + value.substring(valLen - 4, valLen);
        this.form.oldPhone = value;
    }

    // 给父组件传值
    @Emit('phoneCloseMsg')
    private closeTodo(num: number): number {
        // 清除倒计时
        this.codeFlag = false;
        clearInterval(this.interVal);
        this.time = 60;
        this.resetForm('form');
        this.step = 1;
        return num;
    }
    private resetForm(formName: any) {
        const elForm = this.$refs[formName]! as ElForm;
        this.form.newphone = '';
        this.form.regCode = '';
        elForm.resetFields();
    }
    //  下一步
    private next(submitName: string) {
        // 判断校验是否通过
        const formData = this.$refs[submitName]! as ElForm;
        formData.validate((valid) => {
            if (valid) {
                this.$httpService.postData({mobile: this.form.oldPhone, code: this.form.regCode},
                '/apiProxy/api/frontend/users/security/mobile-update-captcha/check')
                .then((res: any) => {
                    this.step = 2;
                    // 清除倒计时
                    this.codeFlag = false;
                    clearInterval(this.interVal);
                    this.time = 60;
                    this.resetForm('form');
                });
            } else {
                console.log('error submit!!');
                return false;
            }
        });
    }
    //  确定按钮
    private closeDialog(num: number, submitName?: string) {
        if (num === 3) {
            // 判断校验是否通过
            if (submitName !== undefined) {
                const formData = this.$refs[submitName]! as ElForm;
                formData.validate((valid) => {
                    if (valid) {
                        this.saveMobile(num);
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }
        } else {
            this.showDialog = false;
            this.closeTodo(num); // 给父组件传值
        }
    }

    // 保存手机
    private saveMobile(num: number) {
        this.$httpService.putData({mobile: this.form.newphone, code: this.form.regCode},
        '/apiProxy/api/frontend/users/security/mobile')
        .then((res: any) => {
            this.$message({
                message: '修改成功！',
                type: 'success',
            });
            const saveUserInfo = {
                ...this.$store.state.userInfo,
                mobile: this.form.newphone,
            };
            this.$store.commit('saveUserInfo', saveUserInfo);
            this.showDialog = false;
            this.closeTodo(num); // 给父组件传值
        });
    }
    // 弹窗关闭后清除所有数据
    private afterClose() {
        this.form.regCode = '';
    }
    // 右上角的关闭按钮
    private beforeClose() {
        this.showDialog = false;
        this.closeTodo(1); // 给父组件传值
    }

    private getCode() {
        this.$httpService.postData({
            mobile: this.step === 1 ? this.form.oldPhone : this.form.newphone,
            messageType: this.step === 1 ? 13 : 14,
        },
        '/apiProxy/api/frontend/users/security/mobile-update-captcha/gen')
        .then((res: any) => {
            this.countDown();
        });
    }

    // 验证码倒计时
    private countDown() {
        this.time = 60;
        this.codeFlag = true;
        this.interVal = setInterval(() => {
            this.time --;
            if (this.time === -1) {
                this.codeFlag = false;
                clearInterval(this.interVal);
            }
        }, 1000);
    }


}
