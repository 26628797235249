
import { Component, Vue, Prop, Watch, Emit  } from 'vue-property-decorator';
@Component({
    name: "ProviceCity",
})
export default class ProviceCity extends Vue {
    @Prop(Number) private provinceCode!: number;
    @Prop(Number) private cityCode!: number;
    @Prop(Boolean) private hasDistrict!: boolean;
    @Prop(Number) private districtCode!: number;
    private selectData: any = {
        province: "",
        city: "",
        district: "",
    };
    private selectDataStr: any = {
        province: "",
        city: "",
        district: "",
    };
    private provinceArr: any = [];
    private cityArr: any = [];
    private districtArr: any = [];
    private hasDistrictFlag: boolean = false;
    private hasDistrictData: boolean = true;
    @Watch("provinceCode", {immediate: true, deep: true})
    private provinceCodeFun(value: number) {
        this.selectData.province = value;
    }
    @Watch("cityCode", {immediate: true, deep: true})
    private cityCodeFun(value: number) {
        this.selectData.city = value;
    }
    @Watch("hasDistrict", {immediate: true, deep: true})
    private hasDistrictFun(value: boolean) {
        this.hasDistrictFlag = value;
    }
    @Watch("districtCode", {immediate: true, deep: true})
    private districtCodeFun(value: number) {
        this.selectData.district = value;
    }
    @Emit("infoCloseMsg")
    private closeMsg(dataStr: any) {
        return this.selectData;
    }
    private created() {
        this.$nextTick(() => {
            this.sendHttps("province", "");
            if (this.selectData.province && this.selectData.province !== undefined) {
                this.sendHttps("city", this.selectData.province);
            }
            if (this.hasDistrictFlag && this.selectData.city && this.selectData.city !== undefined) {
                this.sendHttps("district", this.selectData.city);
            }
        });
    }
    private sendHttps(type: string, id: any) {
        this.$httpService.getData({parentId: id}, '/apiProxy/api/frontend/config/district')
            .then((res: any) => {
                if (type === "province") {
                    this.provinceArr = res;
                } else if (type === "city") {
                    this.cityArr = res;
                } else {
                    this.districtArr = res;
                    if (res.length === 0) {
                        this.hasDistrictData = false;
                    } else {
                        this.hasDistrictData = true;
                    }
                }
            });
    }
    private provinceChange(value: any) {
        this.sendHttps("city", value);
        this.$nextTick( () => {
            console.log("provinceRef========>", (this.$refs.provinceRef as any).selectedLabel);
            this.selectDataStr.province = (this.$refs.provinceRef as any).selectedLabel;
            this.selectDataStr.city = null;
            this.selectData.city = null;
            this.selectData.district = null;
            this.closeMsg(this.selectDataStr);
        });
    }
    private cityChange(value: any) {
        if (this.hasDistrictFlag) {
            this.sendHttps("district", value);
        }
        this.$nextTick( () => {
            console.log("cityRef========>", (this.$refs.cityRef as any).selectedLabel);
            this.selectDataStr.city = (this.$refs.cityRef as any).selectedLabel;
            this.selectDataStr.district = null;
            this.selectData.district = null;
            this.closeMsg(this.selectDataStr);
        });
    }
    private districtChange(value: any) {
        this.$nextTick( () => {
            console.log("districtRef========>", (this.$refs.districtRef as any).selectedLabel);
            this.selectDataStr.district = (this.$refs.districtRef as any).selectedLabel;
            this.closeMsg(this.selectDataStr);
        });
    }
}
