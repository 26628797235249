
import { ElForm } from 'node_modules/element-ui/types/form';
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { encryptedData } from '@/utils/encrypt';
@Component({
  name: 'PwdEditDialog',
})
export default class PwdEditDialog extends Vue {
    private showDialog: boolean = false; // 弹窗显示与关闭
    @Prop({
        type: Boolean,
        default: false,
    }) private exitFlag!: boolean; // 接收父组件传过来的值
    @Prop(String) private phoneNum!: number; // 接收父组件传过来的值
    private form = {
        newPassword: '',
        mobile: '',
        mobileAbbr: '',
        code: '',
    };
    private codeFlag: boolean = false;
    private time: number = 60;
    private interVal: any = null;
    private rules: any = {
        code: [
            { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
        newPassword: [
            { required: true, message: '请输入新密码', trigger: 'blur' },
            { validator: this.validateNewPwd, trigger: 'blur' },
        ],
    };
    private validateNewPwd(rule: any, value: string, callback: any) {
        if (value === '') {
            callback(new Error('请输入密码'));
        } else if (value !== '' && (value.length < 6 || value.length > 16)) {
            callback(new Error('密码长度在6~16位'));
        } else {
            callback();
        }
    }
    @Watch('exitFlag') // 监听父组件传过来的值
    private exitFlagFun(value: boolean) {
        this.showDialog = value;
    }
    @Watch('phoneNum') // 监听父组件传过来的值
    private phoneNumFun(value: string) {
        const valLen = value.length;
        this.form.mobileAbbr = value.substring(0, 3) + '****' + value.substring(valLen - 4, valLen);
        this.form.mobile = value;
    }
    // 给父组件传值
    @Emit('closeMsg')
    private closeTodo(num: number): number {
        // 清除倒计时
        this.codeFlag = false;
        clearInterval(this.interVal);
        this.time = 60;
        this.resetForm('form');
        return num;
    }
    private resetForm(formName: any) {
        const elForm = this.$refs[formName]! as ElForm;
        elForm.resetFields();
    }
    //  确定按钮
    private closeDialog(num: number, submitName?: string) {
        if (num === 3) {
            // 判断校验是否通过
            if (submitName !== undefined) {
                const formData = this.$refs[submitName]! as ElForm;
                formData.validate((valid) => {
                    if (valid) {
                        const paramData: any = {};
                        paramData.mobile = this.form.mobile;
                        paramData.code = this.form.code;
                        paramData.newPassword = encryptedData(this.form.newPassword);
                        this.$httpService.putData(paramData, '/apiProxy/api/frontend/users/security/password')
                        .then((res: any) => {
                            this.$message({
                                message: '修改成功!',
                                type: 'success',
                            });
                            this.showDialog = false;
                            this.closeTodo(num); // 给父组件传值
                        });

                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }
        } else {
            this.showDialog = false;
            this.closeTodo(num); // 给父组件传值
        }
    }
    // 右上角的关闭按钮
    private beforeClose() {
        this.showDialog = false;
        this.closeTodo(1); // 给父组件传值
    }
    private getCode() {
        this.$httpService.postData({
            mobile: this.form.mobile,
            messageType: 14,
        },
        '/apiProxy/api/frontend/users/security/password-update-captcha/gen')
        .then((res: any) => {
            this.countDown();
        });
    }
    // 验证码倒计时
    private countDown() {
        this.time = 60;
        this.codeFlag = true;
        this.interVal = setInterval(() => {
            this.time --;
            if (this.time === -1) {
                this.codeFlag = false;
                clearInterval(this.interVal);
            }
        }, 1000);
    }
}
